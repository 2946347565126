import { useEffect, useRef } from 'react';
import axios from 'axios';
import { API_DOMAIN, useHeaders } from './AccountManager';

const NativeNotifications = () => {
    const headers = useHeaders();
    const intervalIdRef = useRef(null);

    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Разрешение на уведомления получено.');
                }
            });
        } else {
            console.log('Браузер не поддерживает Notifications API.');
        }
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            const isVisible = document.visibilityState === 'visible';
            if (isVisible) {
                stopFunction();
            } else {
                startFunction();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    const startFunction = () => {
        intervalIdRef.current = setInterval(() => {
            axios.get(`${API_DOMAIN}/Notifications.php?F=CHECK`, { headers: headers }).then((res) => {
                if (parseInt(res.data) > 0) {
                    showNotification('Element', 'У вас есть новые уведомления, зайдите и посмотрите');
                }
            });
        }, 25000);
    };

    const stopFunction = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    };

    const showNotification = (title, body) => {
        if (Notification.permission === 'granted') {
            const notification = new Notification(title, {
                body: body,
            });
            notification.onclick = (e) => {
                e.preventDefault();
                window.open('/');
            };
        }
    };

    return null;
};

export default NativeNotifications;
