import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Animate, AnimateElement } from './Function';
import { API_DOMAIN, useAccountData, useHeaders } from './AccountManager';
import { NavLink } from 'react-router-dom';
import { Handle_Avatar, Handle_SongCover, Handle_TimeAge, Handle_Subscribers } from './Handlers';
import { I_Home, I_Messenger, I_Music, I_Settings, I_EPACK, I_GoldStar } from '../UI/IconPack';
import axios from 'axios';
import { PreloadNotifications } from '../UI/Preload';
import NativeNotifications from './NativeNotifications';

export const NavButton = ({ to, className, children }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);
  };
  return (
    <button onClick={handleClick} className={className}>
      {children}
    </button>
  );
};

export const LeftNavButton = ({ target, children, className }) => {
  const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? 'ActiveButton' : '';
  };
  return (
    <NavButton to={target} className={`${className ? className + ' ' : ''}${getActiveClass(target)}`}>
      {children}
    </NavButton>
  );
}

export const TopBar = ({ title, search, titleText }) => {
  let searchTimeout;
  const AccountData = useAccountData();
  const Headers = useHeaders();
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const searchRef = useRef(null);
  const [searchCategory, setSearchCategory] = useState('Users');
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchLoaded, setSearchLoaded] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [panelisible, setPanelVisible] = useState(false);

  // Уведомления
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);

  const checkNotifications = () => {
    axios.get(`${API_DOMAIN}/Notifications.php?F=CHECK`, { headers: Headers }).then((res) => {
      if (parseInt(res.data) > 0) {
        setNotificationsCount(parseInt(res.data));
      }
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkNotifications();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (searchOpen) {
      AnimateElement(searchRef.current, 'SEARCH-SHOW', 0.2);
    } else {
      if (searchActivated) {
        AnimateElement(searchRef.current, 'SEARCH-HIDE', 0.2);
      }
    }
  }, [searchOpen]);

  const searchRequst = (value, category) => {
    axios.post(API_DOMAIN + '/Search.php', {
      SearchVal: value,
      Category: category
    },
      {
        headers: Headers,
      },
    ).then((res) => {
      setSearchLoaded(true);
      setSearchResult(res.data);
    })
  }
  const searchChangeCategory = (category) => {
    setSearchCategory(category);
    searchRequst(searchInputRef.current.value, category);
  }

  const loadSearch = () => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (searchInputRef.current.value.length > 0) {
        if (!searchActivated) {
          setSearchActivated(true);
        }
        searchRequst(searchInputRef.current.value, searchCategory);
        setSearchOpen(true);
      } else {
        setSearchOpen(false);
      }
    }, 500);
  };

  const toggleNavPanel = () => {
    if (panelisible) {
      Animate('.UI-NavPanel', 'NAV_PANEL_BUTTONS-HIDE', 0.3);
      Animate('.UI-Blur', 'BLUR-HIDE', 0.2);
      Animate('.UI-NM_Content', 'V3-ELEMENT-HIDE', 0.4);
      setPanelVisible(false);
    } else {
      Animate('.UI-NavPanel', 'NAV_PANEL_BUTTONS-SHOW', 0.3);
      Animate('.UI-Blur', 'BLUR-SHOW', 0.2);
      Animate('.UI-NM_Content', 'V3-ELEMENT-SHOW', 0.4);
      setPanelVisible(true);
      if (!notificationsLoaded) {
        axios.post(`${API_DOMAIN}/Notifications.php?F=GET`, {},
          {
            headers: Headers,
          },
        ).then((res) => {
          setNewNotifications(res.data.filter(notification => !notification.Viewed));
          setNotifications(res.data.filter(notification => notification.Viewed));
          setNotificationsLoaded(true);
        })
      }
    }
  };
  const exit = () => {
    localStorage.removeItem('S_KEY');
    localStorage.removeItem('UserData');
    navigate('/auth');
  };

  const HandleNotification = ({ notification }) => {
    const HandleAction = ({ action, content }) => {
      switch (action) {
        case 'PostLike':
          return 'ставит лайк на пост';
        case 'PostDislike':
          return 'ставит дизлайк на пост';
        case 'PostComment':
          content = JSON.parse(content);
          if (content.Text.length > 0) {
            return 'комментирует ваш пост «' + content.Text + '»';
          } else {
            return 'комментирует ваш пост';
          }
        case 'ProfileSubscribe':
          return 'подписывается на вас';
        case 'ProfileUnsubscribe':
          return 'больше не ваш подписчик.';
      }
    }
    const HandleIcon = ({ action }) => {
      switch (action) {
        case 'PostLike':
          return (
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m462.3 62.6c-54.8-46.7-136.3-38.3-186.6 13.6l-19.7 20.3-19.7-20.3c-50.2-51.9-131.8-60.3-186.6-13.6-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" /></svg>
          )
        case 'PostDislike':
          return (
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m473.7 73.8-2.4-2.5c-46-47-118-51.7-169.6-14.8l34.3 103.4-96 64 48 128-144-144 96-64-28.6-86.5c-51.7-37.8-124.4-33.4-170.7 14l-2.4 2.4c-48.7 49.8-50.8 129.1-7.3 182.2l212.1 218.6c7.1 7.3 18.6 7.3 25.7 0l212.2-218.7c43.5-53 41.4-132.3-7.3-182.1z" /></svg>
          )
        case 'PostComment':
          return (
            <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="m3 1c-1.10457 0-2 .89543-2 2v4c0 1.10457.89543 2 2 2v1.5c0 .1844.10149.3538.26407.4408s.35985.0775.51328-.0248l2.87404-1.916h2.34861c1.1046 0 2-.89543 2-2v-4c0-1.10457-.8954-2-2-2z" /></svg>
          )
        case 'ProfileSubscribe':
          return (
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="m416 208h-144v-144c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144h-144c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-144h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" /></svg>
          )
        case 'ProfileUnsubscribe':
          return (
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="m416 208h-384c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" /></svg>
          )
      }
    }
    const handleClick = ({ action, content }) => {
      switch (action) {
        case 'PostLike':
          navigate(`/post/${content}`)
          break;
        case 'PostDislike':
          navigate(`/post/${content}`)
          break;
        case 'PostComment':
          content = JSON.parse(content)
          navigate(`/post/${content.PostID}`)
          break;
        case 'ProfileSubscribe':
          navigate(`/profile/${content}`)
          break;
      }
    }
    return (
      <button onClick={() => handleClick({ action: notification.Action, content: notification.Content })} className={`Notifications-Notification${!notification.Viewed ? ' Notifications-NewNotification' : ''}`}>
        <div className="AvatarContainer">
          <div className="Avatar">
            <Handle_Avatar avatar={notification.Avatar} name={notification.Name} />
          </div>
          <HandleIcon action={notification.Action} />
        </div>
        <div className="NotificationContent">
          <div className="Title">{notification.Name}</div>
          <div className="Text">
            <HandleAction action={notification.Action} content={notification.Content} />
          </div>
        </div>
        <div className="Date"><Handle_TimeAge inputDate={notification.Date} /></div>
      </button>
    )
  }

  return (
    <>
      <header>
        <div className="UI-N_DIV">
          {AccountData.ID ? (
            <>
              {title ? (
                <div className="UI-N_L_AND_N">
                  <NavLink className="UI-Logo" to="/" />
                  {title && <div>{titleText} </div>}
                </div>
              ) : (
                <NavLink className="UI-Logo" to="/" />
              )}
              <div className="Search-Container">
                {search && <input ref={searchInputRef} onChange={loadSearch} className="Search" placeholder="Поиск" type="text" autoComplete="off" />}
                <div className="EBalls">
                  <div className="UI-Eball">E</div>
                  <div className="Count"></div>
                </div>
              </div>
              <div className="AvatarContainer">
                <div onClick={toggleNavPanel} className="Avatar">
                  <Handle_Avatar avatar={AccountData.Avatar} name={AccountData.Name} />
                </div>
                {
                  notificationsCount > 0 && (
                    <div className="UI-NCounter">{notificationsCount}</div>
                  )
                }
                <div className="UI-NavPanel">
                  <NavButton to={`/profile/${AccountData.Username}`}>Мой профиль</NavButton>
                  <button onClick={exit}>Выйти</button>
                </div>
              </div>
            </>
          ) : (
            <div className="UI-N_L_AND_N">
              <NavLink className="UI-Logo" to="/auth" />
              <>Element</>
            </div>
          )}
        </div>
        <NativeNotifications />
      </header>
      {
        searchActivated && (
          <div ref={searchRef} className="Search-Result">
            <div className="Category">
              <button onClick={() => { searchChangeCategory('Users') }} className={searchCategory === 'Users' ? 'ActiveButton' : ''}>Пользователи</button>
              <button onClick={() => { searchChangeCategory('Music') }} className={searchCategory === 'Music' ? 'ActiveButton' : ''}>Музыка</button>
            </div>
            <div className="UI-ScrollView">
              <div className="Search-R_Elements">
                {
                  searchLoaded && (
                    <>
                      {searchResult.Content.length > 0 ? (
                        <>
                          {searchResult.Category === 'Users' && (
                            searchResult.Content.map((user, i) => (
                              <NavButton key={i} to={`/profile/${user.Username}`} className="Search-R_Element">
                                <div className="Avatar">
                                  <Handle_Avatar avatar={user.Avatar} name={user.Name} />
                                </div>
                                <div>
                                  <div className="Name">{user.Name}</div>
                                  <div className="Posts"><Handle_Subscribers count={user.Subs} /> • {user.Posts} постов</div>
                                </div>
                              </NavButton>
                            ))
                          )}
                          {searchResult.Category === 'Music' && (
                            searchResult.Content.map((song, i) => (
                              <NavButton key={i} to={`/music/id/${song.ID}`} className="Search-R_Element">
                                <Handle_SongCover cover={song.Cover} />
                                <div>
                                  <div className="Name">{song.Title}</div>
                                  <div className="Posts">{song.Artist}</div>
                                </div>
                              </NavButton>
                            ))
                          )}
                        </>
                      ) : (
                        <div className="Search-Error">Ой, такого не нашлось.</div>
                      )}
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      <div className="UI-Blur"></div>
      <div className="UI-NM_Content">
        <div className="Notifications">
          <div className="Notifications-Scroll">
            {
              notificationsLoaded ? (
                <>
                  {newNotifications.length > 0 && (
                    <div className="Notifications-New">
                      {newNotifications.map((notification, i) => (
                        <HandleNotification key={`new-${i}`} notification={notification} />
                      ))}
                    </div>
                  )}
                  {notifications.length > 0 && (
                    notifications.map((notification, i) => (
                      <HandleNotification key={`all-${i}`} notification={notification} />
                    ))
                  )}
                </>
              ) : (
                <PreloadNotifications />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export const LeftBar = () => {
  return (
    <div className="UI-L_NAV UI-B_FIRST">
      <LeftNavButton target="/">
        <div className="UI-LN_ICON">
          <I_Home />
        </div>
        Главная
      </LeftNavButton>
      <LeftNavButton target="/chat">
        <div className="UI-LN_ICON">
          <I_Messenger />
        </div>
        Мессенджер
      </LeftNavButton>
      <LeftNavButton target="/music">
        <div className="UI-LN_ICON">
          <I_Music />
        </div>
        Музыка
      </LeftNavButton>
      <LeftNavButton target="/settings">
        <div className="UI-LN_ICON">
          <I_Settings />
        </div>
        Настройки
      </LeftNavButton>
      <LeftNavButton className="PAGE-EPACK" target="/epack">
        <div className="UI-LN_ICON">
          <I_EPACK />
        </div>
        Просмотр EPACK
      </LeftNavButton>
      <LeftNavButton target="/gold">
        <div className="GoldText">
          <div className="UI-LN_ICON">
            <I_GoldStar startColor={'#fab31e'} stopColor={'#fd9347'} />
          </div>
          Подписка
        </div>
      </LeftNavButton>
    </div>
  );
};