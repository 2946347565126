import React, { useState, useEffect, useRef } from 'react';
import { API_DOMAIN, CDN_DOMAIN, useAccountData, useHeaders } from '../System/Elements/AccountManager';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavButton, TopBar } from '../System/Elements/Navigate';
import { PreloadPosts } from '../System/UI/Preload';
import { Handle_Cover, Handle_Avatar, Handle_LinkIcon, HandleUserBlock, Handle_Post, Handle_Text } from '../System/Elements/Handlers';
import ErrorPage from '../Pages/ErrorPage';
import { Window } from '../System/Elements/Modals';
import { I_Dots, I_Messenger } from '../System/UI/IconPack';
import axios from 'axios';
import { FastAverageColor } from 'fast-average-color';
import { hexToRgba } from '../System/Elements/Function';
import { useInView } from 'react-intersection-observer';
import { ProfileSubsWindow } from '../System/UI/subsmodal'

const Profile = () => {
    const AccountData = useAccountData();
    const Headers = useHeaders();
    const params = useParams();
    const username = params.username;
    const postsRef = useRef(null);
    const subscribeButtonRef = useRef(null);
    const [profileData, setProfileData] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [postsLoaded, setPostsLoaded] = useState(false);
    const [postsSI, setPostsSI] = useState(25);
    const [morePostsLoading, setMorePostsLoading] = useState(false);
    const [allPostsLoaded, setAllPostsLoaded] = useState(false);
    const [posts, setPosts] = useState([]);
    const [accentColor, setAccentColor] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { username: navigateUsername } = useParams();

    const [windowOpen, setWindowOpen] = useState(false);
    const [windowTitle, setWindowTitle] = useState('');
    const [windowContent, setWindowContent] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    const openModal = (title) => {
        setModalTitle(title);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        axios.get(API_DOMAIN + 'Profile.php?Username=' + username, { headers: Headers }).then((response) => {
            let profile = response.data;
            let uid = profile.ID;
            if (uid) {
                setProfileData(profile);
                setSubscribed(profile.Subscribed);
                if (profile.Posts > 0) {
                    const data = {
                        UserID: uid,
                    };
                    axios.post(API_DOMAIN + 'LoadPosts.php?F=USER', data, {
                        headers: Headers,
                    }).then(res => {
                        setPosts(res.data);
                        setPostsLoaded(true);
                    })
                } else {
                    setPostsLoaded(true);
                }
            }
            setDataLoaded(true);
            if (profile.Avatar && profile.Avatar !== 'None') {
                try {
                    const fac = new FastAverageColor();
                    fac.getColorAsync(`${CDN_DOMAIN}/Content/Avatars/${profile.Avatar}`)
                        .then((color) => {
                            setAccentColor(color.hex);
                        })
                } catch {
                    setAccentColor(null);
                }
            }
        });

        let currentTab = location.pathname.replace(/^\/profile\/[^/]+\//, '');
        switch (currentTab) {
            case 'info': setActiveTab('info'); break;
            default: setActiveTab('profile'); break;
        };
    }, [username])

    const toggleSubscribe = () => {
        axios.post(`${API_DOMAIN}Subscriptions.php?F=SUB_TO_USER`, { UserID: profileData.ID }, { headers: Headers, });
        setSubscribed(!subscribed);
    }

    const viewSubscribed = () => {
        if (profileData.Subscriptions > 0) {
            axios.post(`${API_DOMAIN}Subscriptions.php?F=GET_SUBSCRIPTIONS`, { UserID: profileData.ID }, { headers: Headers, }).then((res) => {
                if (res.data.length > 0) {
                    setWindowTitle('Подписки');
                    setWindowContent(
                        <div className="Profile-Subs UI-ScrollView">
                            {
                                res.data.map((user, i) => (
                                    <HandleUserBlock key={i} user={user} />
                                ))
                            }
                        </div>
                    );
                    setWindowOpen(true);
                }
            });
        }
    }

    const viewSubscribes = () => {
        if (profileData.Subscribers > 0) {
            axios.post(`${API_DOMAIN}Subscriptions.php?F=GET_SUBSCRIBERS`, { UserID: profileData.ID }, { headers: Headers, }).then((res) => {
                if (res.data.length > 0) {
                    setWindowTitle('Подписчики');
                    setWindowContent(
                        <div className="Profile-Subs UI-ScrollView">
                            {
                                res.data.map((user, i) => (
                                    <HandleUserBlock key={i} user={user} />
                                ))
                            }
                        </div>
                    );
                    setWindowOpen(true);
                }
            });
        }
    }

    const tabClick = (tab) => {
        switch (tab) {
            case 'info': setActiveTab('info'); navigate('info'); break;
            default: setActiveTab('profile'); navigate(`/profile/${navigateUsername}`); break;
        };
    }

    const { ref: postsEndRef, inView: postsEndIsView } = useInView({
        threshold: 0
    });

    useEffect(() => {
        if (postsEndIsView) {
            setMorePostsLoading(true);
            axios.post(`${API_DOMAIN}LoadPosts.php?F=USER`, { UserID: profileData.ID, StartIndex: postsSI }, {
                headers: Headers,
            }).then(res => {
                if (Array.isArray(res.data) && res.data.length > 0) {
                    setPosts(prevPosts => [...prevPosts, ...res.data]);
                    setPostsSI(prevPostsSI => prevPostsSI + 25);
                    setMorePostsLoading(false);
                } else {
                    setAllPostsLoaded(true);
                    setMorePostsLoading(false);
                }
            });
        }
    }, [postsEndIsView]);

    return (
        <>
            <TopBar search={true} />
            <div className="Content Profile-Page">
                {
                    dataLoaded && profileData === '' ? (
                        <ErrorPage />
                    ) : (
                        <>
                            <div className="UI-C_L">
                                <div className="UI-ScrollView">

                                    <div className="UI-Block Profile-InfoBlock UI-B_FIRST">

                                        <div style={{ background: accentColor ? `linear-gradient(20deg, ${hexToRgba(accentColor, 1)}, rgb(161 157 177))` : 'var(--NL_GRADIENT)' }} className="Profile-Cover">{profileData.Cover ? <Handle_Cover cover={profileData.Cover} /> : <div className="UI-PRELOAD"></div>}</div>
                                        <div className="Avatar">{profileData.Avatar ? <Handle_Avatar avatar={profileData.Avatar} name={profileData.Name} /> : <div className="UI-PRELOAD"></div>}</div>

                                        <div className="Name">
                                            {profileData.Name ? (
                                                <>
                                                    {profileData.Name}
                                                    {profileData.Icons && <div className="UI-UserIcons" dangerouslySetInnerHTML={{ __html: profileData.Icons }}></div>}
                                                </>
                                            ) : (
                                                <div className="UI-PRELOAD" style={{ width: '100px', height: '15px' }}></div>
                                            )}
                                        </div>
                                        <div className="IconInfoContainer">
                                            <div className="Info"></div>
                                        </div>
                                        <div className="Username">{profileData.Username ? '@' + profileData.Username : <div className="UI-PRELOAD" style={{ width: '120px', height: '15px' }}></div>}</div>

                                        {dataLoaded && AccountData && AccountData.ID !== profileData.ID && ( // Тут одна и та же функция для создания модального окна (если по простому месседж бокса), но в ней можно менять title, сделал так что бы не создавать пару экземпляров
                                            <div style={{ position: 'relative' }}>
                                                <div className="ButtonsContainer">
                                                    <div className="Buttons">
                                                        {
                                                            subscribed ? (
                                                                <button onClick={toggleSubscribe} ref={subscribeButtonRef} className="Button">Отписаться</button>
                                                            ) : (
                                                                <button onClick={toggleSubscribe} ref={subscribeButtonRef} className="SubButton Button">Подписаться</button>
                                                            )
                                                        }
                                                        <NavButton to={`/chat/${profileData.Username}`} className="Button"><I_Messenger /></NavButton>
                                                        <button className="Button"><I_Dots /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="Info">
                                            <button onClick={viewSubscribed} className="Container">
                                                <div className="Value">{dataLoaded ? profileData.Subscriptions : <div className="UI-PRELOAD" style={{ width: '40px', height: '15px' }}></div>}</div>
                                                <div className="Title">подписок</div>
                                            </button>
                                            <button onClick={viewSubscribes} className="Container">
                                                <div className="Value">{dataLoaded ? profileData.Subscribers : <div className="UI-PRELOAD" style={{ width: '40px', height: '15px' }}></div>}</div>
                                                <div className="Title">подписчиков</div>
                                            </button>
                                            <div className="Container">
                                                <div className="Value">{dataLoaded ? profileData.Posts : <div className="UI-PRELOAD" style={{ width: '40px', height: '15px' }}></div>}</div>
                                                <div className="Title">постов</div>
                                            </div>
                                        </div>
                                        <ProfileSubsWindow
                                            isOpen={isModalOpen}
                                            title={modalTitle}
                                            onClose={closeModal}
                                        />
                                        {profileData.Description &&
                                            <div className="Description">
                                                <div className="Title">описание</div>
                                                <div className="Text">
                                                    <Handle_Text text={profileData.Description} />
                                                </div>
                                            </div>
                                        }
                                        {profileData.Links && profileData.Links.length > 0 &&
                                            <div className="Links">
                                                {profileData.Links.map((link, i) => (
                                                    <button key={i} onClick={() => window.open(link.Link, '_blank')} className="Link">
                                                        <Handle_LinkIcon link={link.Link} /> {link.Title}
                                                    </button>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="UI-C_R Profile-Posts">
                                <div ref={postsRef} className="UI-ScrollView">
                                    <div className="UI-Tabs UI-B_FIRST">
                                        <button className={`Tab${activeTab == `profile` ? ' ActiveTab' : ''}`} onClick={() => tabClick('profile')} >Посты</button>
                                        <button className={`Tab${activeTab == `info` ? ' ActiveTab' : ''}`} onClick={() => tabClick('info')} >Доп. информация</button>
                                    </div>
                                    <Routes>
                                        <Route path="/" element={
                                            <div style={{ marginBottom: 20 }}>
                                                {AccountData.ID ? (
                                                    postsLoaded ? (
                                                        posts.length > 0 ? (
                                                            posts.map((post, i) => (
                                                                <Handle_Post key={i} post={post} profileData={profileData} />
                                                            ))
                                                        ) : (
                                                            <div className="UI-ErrorMessage">Ой, а тут пусто</div>
                                                        )
                                                    ) : (
                                                        <PreloadPosts />
                                                    )
                                                ) : (
                                                    <div className="UI-ErrorMessage">Для просмотра постов нужно иметь аккаунт</div>
                                                )}
                                                {
                                                    !morePostsLoading && postsLoaded && posts.length > 0 && !allPostsLoaded && (
                                                        <span ref={postsEndRef} />
                                                    )
                                                }
                                                {
                                                    postsLoaded && morePostsLoading && (
                                                        <div className="UI-Loading">
                                                            <div className="UI-Loader_1"></div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        } />
                                        <Route path="info" element={
                                            <div id="ProfileInfo">
                                                <div className="UI-Block">
                                                    <div id="PROFILE_REG_DATE">
                                                        Дата регистрации: {profileData.CreateDate}
                                                    </div>
                                                </div>
                                            </div>
                                        } />
                                    </Routes>
                                </div>
                            </div>
                            <Window title={windowTitle} content={windowContent} style={{ maxWidth: 400 }} contentStyle={{ height: '50vh' }} isOpen={windowOpen} setOpen={setWindowOpen} />
                        </>
                    )
                }
            </div>
        </>
    )
};

export default Profile;
