import React, { useState, useEffect } from 'react';

export const ProfileSubsWindow = ({ isOpen, title, onClose }) => {
    const [content, setContent] = useState([]);
    const [visible, setVisible] = useState(isOpen);
    
    const addContent = (newElement) => {
        setContent((prevContent) => [...prevContent, newElement]);
    };
    
    useEffect(() => {
        if (isOpen) {
            setVisible(true);
        } else {
            const timeout = setTimeout(() => setVisible(false), 300); // matches animation duration
            return () => clearTimeout(timeout);
        }
    }, [isOpen]);

    if (!visible) {
        return null;
    }


    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(5px)',
                    zIndex: '999',
                    opacity: isOpen ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                }}
                onClick={onClose}
            />
            <div
                id="PROFILE-SUBS_WINDOW"
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    zIndex: '1000',
                    maxWidth: '600px',
                    width: '90%',
                    maxHeight: '80vh',
                    animation: isOpen ? 'fadeIn 0.3s ease-in-out' : 'fadeOut 0.3s ease-in-out',
                    opacity: isOpen ? 1 : 0,
                }}
            >
                <div
                    className="TopBar"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '10px',
                        borderBottom: '1px solid #ccc',
                    }}
                >
                    <div
                        className="Title"
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        {title}
                    </div>
                    <button
                        id="UI-AW_Close"
                        onClick={onClose}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '24px',
                            height: '24px',
                        }}
                    >
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 10.586l4.293-4.293 1.414 1.414L13.414 12l4.293 4.293-1.414 1.414L12 13.414l-4.293 4.293-1.414-1.414L10.586 12 6.293 7.707l1.414-1.414L12 10.586z" />
                        </svg>
                    </button>
                </div>
                <div
                    className="UI-AW_Content"
                    style={{
                        height: '60vh',
                        marginTop: '20px',
                        overflowY: 'auto',
                    }}
                >
                    <div className="Profile-Subs UI-ScrollView">
                        {content.map((element, index) => (
                            <div key={index}>{element}</div>
                        ))}
                    </div>
                </div>
            </div>
            <style>{`
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                @keyframes fadeOut {
                    from { opacity: 1; }
                    to { opacity: 0; }
                }
            `}</style>
        </>
    );
};
