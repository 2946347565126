import { useEffect, useRef, useState } from 'react';
import { TopBar, LeftBar, NavButton } from '../System/Elements/Navigate';
import { Handle_Avatar, Handle_Cover, Handle_Text, Handle_Theme, Handle_LinkIcon } from '../System/Elements/Handlers';
import { API_DOMAIN, useAccountData, useHeaders, GetLinks, updateUserData } from '../System/Elements/AccountManager';
import { Partitions } from '../System/Elements/Partitions';
import { Modals } from '../System/Elements/Modals';
import { I_Plus } from '../System/UI/IconPack';
import axios from 'axios';
import '../System/UI/ThemePrew.css';

const Settings = () => {
  const AccountData = useAccountData();
  const Headers = useHeaders();
  const [username, setUsername] = useState();
  const [description, setDescription] = useState();
  const [inputRange, setInputRange] = useState(5);
  const [Avatart, setAvatar] = useState();
  const [Cover, setCover] = useState();
  const AvatarUpload = useRef(null);
  const emailRef = useRef(null);
  const CoverUpload = useRef(null);
  const UsernameElement = useRef(null);
  const DescriptionElement = useRef(null);
  const PartitionsPanel = useRef();
  const [partitionOpen, setPartitionOpen] = useState(false);
  const [PartitionType, setPartitionType] = useState();
  const [updateUsername, setUpdateUsername] = useState();
  const [updateDescription, setUpdateDescription] = useState();
  const [OpenModals, setOpenModals] = useState(false);
  const [ModalData, setModalData] = useState({});

  const [userDataLinks, setUserDataLinks] = useState([]);

  const [typeViewPosts, setTypeViewPosts] = useState(() => {
    const saved = localStorage.getItem('S-PostsType');
    return saved || 'LATEST';
  });

  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('S-Theme') || 'LIGHT');
  let themes = [
    {
      name: 'Светлая',
      id: 'LIGHT',
      class: 'Theme-Light',
      goldStatus: false,
    },
    {
      name: 'Золотая',
      id: 'GOLD',
      class: 'Theme-Gold',
      goldStatus: true,
    },
    {
      name: 'Тёмная',
      id: 'DARK',
      class: 'Theme-Dark',
      goldStatus: false,
    },
    {
      name: 'Золотая тёмная',
      id: 'GOLD-DARK',
      class: 'Theme-Gold-Dark',
      goldStatus: true,
    },
    {
      name: 'AMOLED',
      id: 'AMOLED',
      class: 'Theme-Amoled',
      goldStatus: false,
    },
    {
      name: 'Золотая AMOLED',
      id: 'AMOLED-GOLD',
      class: 'Theme-Amoled-Gold',
      goldStatus: true,
    },
  ];
  themes = AccountData.GoldStatus ? themes : themes.filter((t) => t.goldStatus === false);

  useEffect(() => {
    localStorage.setItem('S-PostsType', typeViewPosts);
  }, [typeViewPosts]);
  useEffect(() => {
    updateUserData();
  }, []);
  useEffect(() => {
    setAvatar(<Handle_Avatar avatar={AccountData.Avatar} name={AccountData.Username} />);
    setCover(<Handle_Cover cover={AccountData.Cover} />);
    setUsername(AccountData.Name);
    setDescription(AccountData.Description);
    setUpdateDescription(AccountData.Description);
    setUpdateUsername(AccountData.Name);
  }, []);
  useEffect(() => {
    const fetchLinks = async () => {
      await GetLinks({
        setUserDataLinks: setUserDataLinks,
        userDataLinks: userDataLinks,
        handlePartitionClick: handlePartitionClick,
      });
    };
    fetchLinks();
  }, []);

  const handlePartitionClick = ({ type, link }) => {
    setPartitionOpen(true);
    setPartitionType({ type, link });
  };

  const updateLink = (updatedLink) => {
    setUserDataLinks((prevLinks) => prevLinks.map((link) => (link.id === updatedLink.id ? updatedLink : link)));
  };

  const handle_confirmEdit = async ({ value, type, refElement }) => {
    let formData = new FormData();
    let typeRequest;
    typeRequest = type === 'description' ? 'CHANGE_DEC' : 'CHANGE_NAME';
    formData.append(type === 'description' ? 'Description' : 'Name', value);
    console.log(formData);
    await axios
      .post(`${API_DOMAIN}Settings.php?F=` + typeRequest, formData, {
        headers: Headers,
      })
      .then((data) => {
        console.log(data.data);
        if (data.data.Type === 'Verify') {
          updateUserData();
          if (type === 'description') {
            setUpdateDescription(value);
            setDescription(value);
          } else if (type === 'username') {
            setUpdateUsername(value);
            setUsername(value);
          }
        }
        if (data.data.Type === 'Error') {
          setOpenModals(true);
          setModalData({
            Title: data.data.Type === 'Error' && 'Ошибка',
            Message: data.data.Content,
          });
        }
      });
  };

  const deleteAttachment = ({ type }) => {
    axios
      .post(
        API_DOMAIN + `Settings.php?F=DELETE_${type === 'Avatar' ? 'AVATAR' : 'COVER'}`,
        {},
        {
          headers: Headers,
        }
      )
      .then((data) => {
        if (data.data.Type === 'Error') {
          setOpenModals(true);
          setModalData({
            Title: data.data.Type === 'Error' && 'Ошибка',
            Message: data.data.Content,
          });
        } else {
          type === 'Avatar'
            ? setAvatar(<Handle_Avatar avatar={AccountData.Avatar} name={AccountData.Username} />)
            : setCover(<Handle_Cover cover={AccountData.Cover} />);
        }
      });
  };

  const setTheme = (theme) => {
    localStorage.setItem('S-Theme', theme);
    Handle_Theme();
    setSelectedTheme(theme);
  };

  return (
    <>
      {OpenModals ? <Modals data={ModalData} setOpenModals={setOpenModals} /> : null}
      <TopBar search={true} />
      <div className="Content">
        <LeftBar />
        <div className="Settings-Page UI-PAGE_BODY">
          <div className="UI-C_R">
            <div className="UI-ScrollView">
              <div className="UI-Block Profile-InfoBlock UI-B_FIRST">
                <div className="UI-Title" style={{ width: '100%' }}>
                  Мой профиль
                </div>

                <div className="Profile-Cover">{Cover}</div>
                <div className="Avatar">{Avatart}</div>

                <div className="Name">
                  <div id="S-PROFILE_PREW_NAME">{updateUsername}</div>
                  <div className="UserIcons"></div>
                </div>

                <div className="IconInfoContainer">
                  <div className="Info"></div>
                </div>

                <div className="Username">@{AccountData.Username}</div>
                <div className="Settings-PRFL_Email" ref={emailRef}>
                  {AccountData.Email}
                </div>
                {updateDescription ? (
                  <div className="Description">
                    <div className="Title">описание</div>
                    <div id="S-PROFILE_PREW_DEC" className="Text">
                      <Handle_Text text={updateDescription} />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="UI-C_L" ref={PartitionsPanel}>
            <div id="SETTINGS-BODY" className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Редактировать аккаунт</div>
                <div className="Settings-CP_Cover">
                  <div className="Cover" id="Cover_upload">
                    {Cover}
                  </div>
                  <div className="Settings-ChangeButtons">
                    <form id="S-CP_COVER_FORM" encType="multipart/form-data">
                      <input
                        autoComplete="off"
                        id="S-CP_UPLOAD_COVER"
                        name="Cover"
                        type="file"
                        accept="image/*"
                        ref={CoverUpload}
                        onChange={() => {
                          const formData = new FormData();
                          formData.append('Cover', CoverUpload.current.files[0]);
                          axios
                            .post(API_DOMAIN + 'Settings.php?F=CP_UPLOAD_COVER', formData, {
                              headers: Headers,
                            })
                            .then((response) => {
                              if (response.data) {
                                const block = document.querySelector('#Cover_upload');
                                if (block) {
                                  setCover(<Handle_Cover cover={response.data.Content} />);
                                } else {
                                  console.error('Cover element not found');
                                }
                                console.log('Avatar uploaded successfully');
                              } else {
                                console.error('Error uploading Avatar');
                              }
                            })
                            .catch((error) => {
                              console.error('Error uploading Avatar', error);
                            });
                        }}
                      />
                    </form>
                    <label htmlFor="S-CP_UPLOAD_COVER" className="Button">
                      Загрузить новый
                      <input id="S-CP_UPLOAD_COVER" name="Cover" type="file" />
                    </label>
                    <button
                      id="S-CP_DL_COVER"
                      className="ButtonDL"
                      onClick={() => {
                        deleteAttachment({ type: 'Cover' });
                      }}
                    >
                      Удалить
                    </button>
                  </div>
                </div>
                <div className="Settings-CP_Avatar">
                  <div className="Avatar" id="Avatar_upload">
                    {Avatart}
                  </div>
                  <div className="Settings-ChangeButtons">
                    <form id="S-CP_UPLOAD_AVATAR_FORM" encType="multipart/form-data">
                      <input
                        id="S-CP_UPLOAD_AVATAR"
                        name="Avatar"
                        type="file"
                        accept="image/*"
                        ref={AvatarUpload}
                        onChange={() => {
                          const formData = new FormData();
                          formData.append('Avatar', AvatarUpload.current.files[0]);
                          axios
                            .post(API_DOMAIN + 'Settings.php?F=CP_UPLOAD_AVATAR', formData, {
                              headers: Headers,
                            })
                            .then((response) => {
                              if (response.data) {
                                setAvatar(
                                  <Handle_Avatar avatar={response.data.Content} name={response.data.Username} />
                                );

                                console.log('Avatar uploaded successfully');
                              } else {
                                console.error('Error uploading Avatar');
                              }
                            })
                            .catch((error) => {
                              console.error('Error uploading Avatar', error);
                            });
                        }}
                      />
                    </form>
                    <label htmlFor="S-CP_UPLOAD_AVATAR" className="Button">
                      Загрузить новый
                    </label>
                    <button
                      id="S-CP_DL_AVATAR"
                      className="ButtonDL"
                      onClick={() => {
                        deleteAttachment({ type: 'Avatar' });
                      }}
                    >
                      Удалить
                    </button>
                  </div>
                </div>
                <div id="S-CP_NameContainer" className="Settings-CP_Input_container">
                  <div className="Settings-CP_IC_Title">имя (псевдоним)</div>
                  <input
                    autoComplete="off"
                    className="UI-Input"
                    id="S-CP_Name"
                    value={username}
                    type="text"
                    maxLength="30"
                    placeholder="Введите текст"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  {updateUsername !== username && (
                    <div className="Settings-Q_container" ref={UsernameElement}>
                      <div
                        className="Question"
                        style={{
                          animation:
                            updateUsername !== username
                              ? '0.8s ease 0s 1 normal forwards running SETTINGS-SHOW_INPUT'
                              : '0.8s ease 0s 1 normal forwards running SETTINGS-HIDE_INPUT',
                        }}
                      >
                        <button
                          className="Apply"
                          onClick={() =>
                            handle_confirmEdit({
                              value: username,
                              type: 'username',
                              refElement: UsernameElement,
                            })
                          }
                        >
                          Применить
                        </button>
                        <button className="Back" onClick={() => setUsername(AccountData.Name)}>
                          Отменить
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div id="S-CP_DecContainer" className="Settings-CP_Input_container">
                  <div className="Settings-CP_IC_Title">описание профиля</div>
                  <textarea
                    className="UI-Input"
                    id="S-CP_Dec"
                    type="text"
                    maxLength="100"
                    placeholder="Введите текст"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  {updateDescription !== description && (
                    <div className="Settings-Q_container" ref={DescriptionElement}>
                      <div
                        className="Question"
                        style={{
                          animation:
                            updateDescription !== description
                              ? '0.8s ease 0s 1 normal forwards running SETTINGS-SHOW_INPUT'
                              : '0.8s ease 0s 1 normal forwards running SETTINGS-HIDE_INPUT',
                        }}
                      >
                        <button
                          className="Apply"
                          onClick={() =>
                            handle_confirmEdit({
                              value: description,
                              type: 'description',
                              refElement: DescriptionElement,
                            })
                          }
                        >
                          Применить
                        </button>
                        <button className="Back" onClick={() => setDescription(AccountData.Description)}>
                          Отменить
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {AccountData.GoldStatus === true && (
                  <div className="Settings-CP_Input_container">
                    <div className="Settings-CP_IC_Title">ссылки</div>
                    <div className="Settings-Links">
                      <button
                        onClick={() => {
                          handlePartitionClick({ type: 'add_link' });
                        }}
                      >
                        <I_Plus />
                        Добавить
                      </button>

                      {userDataLinks.map((link, index) => (
                        <button
                          className="Link"
                          onClick={() => {
                            handlePartitionClick({
                              type: 'edit_link',
                              link,
                              updateLink,
                            });
                          }}
                          key={index}
                        >
                          <Handle_LinkIcon link={link.Link} />
                          {link.Title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="UI-PartitionName">Конфиденциальность</div>
              <div className="UI-Buttons">
                {/* <button id="PARTITION-SESSIONS" onClick={() => handlePartitionClick({ type: 'sessions' })}>
                  Сессии
                </button>*/}
                <button id="PARTITION-CHANGE_EMAIL" onClick={() => handlePartitionClick({ type: 'change_email' })}>
                  Сменить почту
                </button>
                <button
                  id="PARTITION-CHANGE_PASSWORD"
                  onClick={() => handlePartitionClick({ type: 'change_password' })}
                >
                  Сменить пароль
                </button>
              </div>
              <div className="UI-PartitionName">Другое</div>
              <div className="UI-Buttons">
                <button onClick={() => handlePartitionClick({ type: 'profile_status' })}>Мой статус</button>
                <button onClick={() => handlePartitionClick({ type: 'gold_info' })}>Подписка Gold</button>
                <button onClick={() => handlePartitionClick({ type: 'authors' })}>Авторы</button>
                <NavButton to="/info/advantages">Информация</NavButton>
              </div>
              <div className="UI-PartitionName">Первым показывать на главной</div>
              <div className="UI-Block">
                <div className="Settings-PType">
                  {['LATEST', 'REC', 'SUBSCRIPTIONS'].map((type) => (
                    <button
                      key={type}
                      ptype={type}
                      className={typeViewPosts === type ? 'Active' : ''}
                      onClick={() => setTypeViewPosts(type)}
                    >
                      {type === 'LATEST' ? 'Последние' : type === 'REC' ? 'Рекомендации' : 'Подписки'}
                    </button>
                  ))}
                </div>
              </div>
              <div className="UI-PartitionName">Скорость анимаций</div>
              <div className="UI-Block">
                <div className="Settings-Anim">
                  <item>быстро</item>
                  <item>стандарт</item>
                  <item>медленно</item>
                </div>
                <input
                  autoComplete="off"
                  id="S-CHANGE_ANIM"
                  className="UI-Range"
                  style={{ width: '90%' }}
                  type="range"
                  min="0"
                  max="10"
                  value={inputRange}
                  onChange={(e) => setInputRange(e.target.value)}
                />
              </div>
              <div className="UI-PartitionName">Смена темы</div>
              <div className="UI-Block">
                <div className="Settings-Themes">
                  {themes.map((theme) => (
                    <div className={`${theme.class} ChangeTheme`} onClick={() => setTheme(theme.id)}>
                      <div className="TH-Container">
                        <div className="TH-TopBar"></div>
                        <div className="TH-Posts">
                          <div className="TH-AddPost">
                            <div className="TH-Button"></div>
                          </div>
                          <div className="TH-Post"></div>
                          <div className="TH-Post"></div>
                        </div>
                        <div className="TH-BottomBar"></div>
                      </div>
                      <div className={`Info${selectedTheme === theme.id ? ' Selected' : ''}`}>{theme.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {partitionOpen ? (
              <Partitions
                setPartitionOpen={setPartitionOpen}
                propsPartitionType={PartitionType}
                setOpenModals={setOpenModals}
                setModalData={setModalData}
                //##################
                userDataLinks={userDataLinks}
                setUserDataLinks={setUserDataLinks}
                //Добавил эти 2 параметра так как хароми их забыл передать
                // это исправляет ошибку с неизвестным useState
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
