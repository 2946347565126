import { useState } from "react";
import axios from "axios";
import { API_DOMAIN, GetLinks, Headers } from "../AccountManager";
import { Handle_LinkIcon } from "../Handlers";

export const EditLink = ({
  type,
  setOpenModals,
  setModalData,
  handlePartitionClick,
  closePartition,
  userDataLinks,
  setUserDataLinks,
}) => {
  const [titleValue, setTitleValue] = useState(type.link.Title);
  const [linkValue, setLinkValue] = useState(type.link.Link);

  const Handle_EditLink = ({ LinkID }) => {
    const requestEditLink = async () => {
      const formData = new FormData();
      formData.append("LinkID", LinkID);
      formData.append("Title", titleValue);
      formData.append("Link", linkValue);

      const response = await axios.post(
        API_DOMAIN + "Settings.php?F=EDIT_LINK",
        formData,
        {
          headers: Headers,
        }
      );
      if (response.data.Type === "Error") {
        setOpenModals(true);
        setModalData({
          Title: "Ошибка",
          Message: response.data.Content,
        });
      }
      if (response.data.Type === "Verify") {
        setOpenModals(true);
        setModalData({
          Title: "Информация",
          Message: `Успешно изменена ссылка на «${linkValue}»`,
        });
        GetLinks({
          setUserDataLinks: setUserDataLinks,
          userDataLinks: userDataLinks,
          handlePartitionClick: handlePartitionClick,
        }).then(() => {
          closePartition();
        });
      }
    };
    requestEditLink();
  };

  const Handle_DeleteLink = ({ LinkID }) => {
    const requestDelete = async () => {
      const formData = new FormData();
      formData.append("LinkID", LinkID);
      const response = await axios.post(
        API_DOMAIN + "Settings.php?F=DELETE_LINK",
        formData,
        {
          headers: Headers,
        }
      );

      if (response.data.Type === "Error") {
        setOpenModals(true);
        setModalData({
          Title: "Ошибка",
          Message: response.data.Content,
        });
      }
      if (response.data.Type === "Verify") {
        setOpenModals(true);
        setModalData({
          Title: "Информация",
          Message: `Ссылка «${LinkID}» удалена`,
        });
        GetLinks({
          setUserDataLinks: setUserDataLinks,
          userDataLinks: userDataLinks,
          handlePartitionClick: handlePartitionClick,
        }).then(() => {
          closePartition();
        });
      }
    };
    requestDelete();
  };

  return (
    <>
      <div className="UI-PB_ImagesGrup">
        <img
          src="/System/Images/All/Settings_EditLink.png"
          className="UI-PB_Image"
          alt="фыр"
        />
        <div id="S-CP_LINK_ICON" className="UI-PB_LinkImage">
          <Handle_LinkIcon link={type.link.Link} />
        </div>
      </div>
      <input
        id="S-CP_E_LinkTitle"
        placeholder="Имя ссылки"
        maxLength="50"
        type="text"
        value={titleValue}
        onChange={(e) => {
          setTitleValue(e.target.value);
        }}
      />
      <input
        id="S-CP_E_Link"
        placeholder="Ссылка"
        maxLength="150"
        type="text"
        value={linkValue}
        onChange={(e) => {
          setLinkValue(e.target.value);
        }}
      />
      <button
        id="S-CP_DeleteLink"
        className="UI-PB_Button"
        onClick={() => Handle_DeleteLink({ LinkID: type.link.ID })}
      >
        Удалить
      </button>
      <button
        id="S-CP_EditLink"
        className="UI-PB_NextButton"
        onClick={() => {
          if (
            titleValue.length > 0 &&
            linkValue.length > 0 &&
            (titleValue !== type.link.Title || linkValue !== type.link.Link)
          ) {
            Handle_EditLink({
              LinkID: type.link.ID,
            });
          }
        }}
      >
        Изменить
      </button>
    </>
  );
};
