import { useState, useEffect, useRef, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { API_DOMAIN, CDN_DOMAIN, useHeaders } from '../System/Elements/AccountManager';
import { PreloadSongs } from '../System/UI/Preload';
import { Handle_SongCover } from '../System/Elements/Handlers';
import { Animate } from '../System/Elements/Function';
import { I_Like, I_Music, I_SongInfo, I_Random, I_Loop, I_PlayerClose, I_Dots, I_Scrollbutton } from '../System/UI/IconPack';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { PlayButton, NextButton, BackButton, formatTime } from '../System/Elements/MusicPlayer';
import { Window } from '../System/Elements/Modals';

const playerInitialState = {
    currentCover: null,
    oldCover: null,
    selectedSong: false,
    selectedSongData: [],
    playing: false,
    progress: 0,
    duration: '0:00',
    currentDuration: '0:00',
    loop: false,
    random: false,
    volume: (() => {
        const savedVolume = localStorage.getItem('M-Volume');
        return savedVolume ? parseFloat(savedVolume) * 100 : 100;
    })(),
}

const playerReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CURRENT_COVER':
            return { ...state, currentCover: action.payload };
        case 'SET_OLD_COVER':
            return { ...state, oldCover: action.payload };
        case 'SET_SELECTED_SONG':
            return { ...state, selectedSong: action.payload };
        case 'SET_SELECTED_SONG_DATA':
            return { ...state, selectedSongData: action.payload };
        case 'SET_PLAYING':
            return { ...state, playing: action.payload };
        case 'SET_PROGRESS':
            return { ...state, progress: action.payload };
        case 'SET_DURATION':
            return { ...state, duration: action.payload };
        case 'SET_CURRENT_DURATION':
            return { ...state, currentDuration: action.payload };
        case 'SET_LOOP':
            return { ...state, loop: action.payload };
        case 'SET_RANDOM':
            return { ...state, random: action.payload };
        case 'SET_VOLUME':
            return { ...state, volume: action.payload };
        default:
            return state;
    }
}

const Handle_Song = ({ song, category, selectSong }) => {
    const coverURL = song.Cover ? song.Cover.simple_image ? `${CDN_DOMAIN}/Content/Simple/${song.Cover.simple_image}` : `${CDN_DOMAIN}/Content/Music/Covers/${song.Cover.image}` : null;
    return (
        <div onClick={() => { selectSong(song, category) }} className="Music-SongPrew">
            <div className="Cover">
                {song.Cover ? (
                    <>
                        <img
                            src={coverURL}
                            loading="lazy"
                        />
                        <img
                            className="CoverShadow"
                            src={coverURL}
                            loading="lazy"
                        />
                    </>
                ) : (
                    <div className="Music-NoneCover"><I_Music /></div>
                )}
            </div>
            <div className="MetaAndButton">
                <div className="Metadata">
                    <div className="Name">{song.Title}</div>
                    <div className="Author">{song.Artist}</div>
                </div>
                <button className="UI-GovernButton">
                    <I_Dots />
                </button>
                <div className="UI-GovernButtons"></div>
            </div>
        </div>
    );
}

const HandleFullCover = ({ cover }) => {
    return (
        cover ? (
            <img
                className="Cover"
                src={`${CDN_DOMAIN}/Content/Music/Covers/${cover.image}`}
            />
        ) : (
            <div className="Music-NoneCover">
                <I_Music />
            </div>
        )
    )
}

const Category = ({ category, selectSong, loadMore }) => {
    const [isHovered, setIsHovered] = useState(false);
    const scrollRef = useRef(null);
    const { ref: endSpanRef, inView: inView } = useInView({
        threshold: 0
    });

    useEffect(() => {
        if (inView) {
            loadMore(category);
        }
    }, [inView])

    if (category.get === 'FAVORITES' && category.songs.length === 0) {
        return null;
    }

    const animations = {
        hide: {
            opacity: 0,
            filter: 'blur(2px)',
        },
        show: {
            opacity: 1,
            filter: 'blur(0px)',
        }
    };

    const scrollLeft = () => {
        scrollRef.current.scrollTo({
            left: scrollRef.current.scrollLeft - 350,
            behavior: 'smooth'
        });
    }

    const scrollRight = () => {
        scrollRef.current.scrollTo({
            left: scrollRef.current.scrollLeft + 350,
            behavior: 'smooth'
        });
    }

    return (
        <div
            className="Music-List"
            onMouseEnter={() => { setIsHovered(true) }}
            onMouseLeave={() => { setIsHovered(false) }}
        >
            <div className="Title">{category.title}</div>
            <div className="SH_L"></div>
            <div className="SH_R"></div>
            <AnimatePresence>
                {
                    isHovered && (
                        <motion.button
                            onClick={scrollLeft}
                            className="ScrollButton SC-L"
                            initial="hide"
                            animate="show"
                            exit="hide"
                            variants={animations}
                        >
                            <I_Scrollbutton />
                        </motion.button>
                    )
                }
            </AnimatePresence>
            <div ref={scrollRef} className="Scroll">
                {
                    category.loaded ? (
                        <>
                            {
                                category.songs.map((song, i) => (
                                    <Handle_Song key={i} song={song} category={category} selectSong={selectSong} />
                                ))
                            }
                            <span ref={endSpanRef}></span>
                        </>
                    ) : (
                        <PreloadSongs />
                    )
                }
            </div>
            <AnimatePresence>
                {
                    isHovered && (
                        <motion.button
                            onClick={scrollRight}
                            className="ScrollButton SC-R"
                            initial="hide"
                            animate="show"
                            exit="hide"
                            variants={animations}
                        >
                            <I_Scrollbutton />
                        </motion.button>
                    )
                }
            </AnimatePresence>
        </div>
    )
}

const AddForm = () => {
    const headers = useHeaders();
    const [title, setTitle] = useState('');
    const [artist, setArtist] = useState('');
    const [album, setAlbum] = useState('');
    const [trackNumber, setTrackNumber] = useState('');
    const [genre, setGenre] = useState('');
    const [releaseYear, setReleaseYear] = useState('');
    const [composer, setComposer] = useState('');
    const [postText, setPostText] = useState('');
    const [coverFile, setCoverFile] = useState('');
    const [audioFile, setAudioFile] = useState(null);

    const send = () => {
        const formData = new FormData();
        formData.append('Title', title);
        formData.append('Artist', artist);
        formData.append('Album', album);
        formData.append('TrackNumber', trackNumber);
        formData.append('Genre', genre);
        formData.append('ReleaseYear', releaseYear);
        formData.append('Сomposer', composer);
        formData.append('AudioFile', audioFile);
        if (coverFile) {
            formData.append('CoverFile', coverFile);
        }
        axios.post(`${API_DOMAIN}/AddMusic.php`, formData, { headers: headers }).then((res) => {
            console.log(res.data);
        })
    }

    return (
        <>
            <div className="BaseInfo">
                <input onChange={(e) => { setCoverFile(e.target.files[0]) }} id="MI-COVER_FILE" type="file" />
                <label className="Cover" htmlFor="MI-COVER_FILE">
                    <I_Music />
                    <div className="Text">
                        {
                            coverFile && coverFile.name ? (
                                coverFile.name
                            ) : (
                                'Выбрать обложку'
                            )
                        }
                    </div>
                </label>
                <div className="Inputs">
                    <div className="Inputs-Title">Основная информация</div>
                    <input value={title} onChange={(e) => { setTitle(e.target.value) }} type="text" placeholder="Название (обязательно)" />
                    <input value={artist} onChange={(e) => { setArtist(e.target.value) }} type="text" placeholder="Исполнитель(и) (обязательно)" />
                    <input value={album} onChange={(e) => { setAlbum(e.target.value) }} type="text" placeholder="Альбом" />
                </div>
            </div>
            <div className="InfoAndFile">
                <input onChange={(e) => { setAudioFile(e.target.files[0]) }} id="MI-AUDIO_FILE" type="file" />
                <label htmlFor="MI-AUDIO_FILE">
                    <div className="Text">
                        {
                            audioFile && audioFile.name ? (
                                audioFile.name
                            ) : (
                                'Выбрать обложку'
                            )
                        }
                    </div>
                </label>
                <div className="Info">Если в файле уже есть метаданные, они подтянуться автоматически, метаданные которые вы напишете в форме запишуться поверх тех которые уже есть в файле.</div>
            </div>
            <div className="AllInfo">
                <div className="Inputs-Title">Вторичная информация</div>
                <div className="Columns">
                    <div className="Column">
                        <input value={trackNumber} onChange={(e) => { setTrackNumber(e.target.value) }} type="text" placeholder="Номер трека" />
                        <input value={genre} onChange={(e) => { setGenre(e.target.value) }} type="text" placeholder="Жанр" />
                        <input value={releaseYear} onChange={(e) => { setReleaseYear(e.target.value) }} type="text" placeholder="Дата выхода" />
                    </div>
                    <div className="Column">
                        <input value={composer} onChange={(e) => { setComposer(e.target.value) }} type="text" placeholder="Композитор" />
                        <textarea value={postText} onChange={(e) => { setPostText(e.target.value) }} type="text" placeholder="Текст поста" hidden>Я добавил эту песню!</textarea>
                    </div>
                </div>
            </div>
            <button onClick={send} className="Send">Опубликовать</button>
        </>
    )
}

const SongMetadata = ({ songData }) => {
    const HandleSongInfoTitle = (title) => {
        switch (title) {
            case 'Title':
                return 'Название';
            case 'Artist':
                return 'Исполнитель(и)';
            case 'Album':
                return 'Альбом';
            case 'Genre':
                return 'Жанр';
            case 'TrackNumber':
                return 'Номер трека';
            case 'ReleaseYear':
                return 'Дата выхода';
            case 'Composer':
                return 'Композитор';
            case 'Duration':
                return 'Длительность';
            case 'Bitrate':
                return 'Скорость потока';
            case 'AudioFormat':
                return 'Аудиоформат';
            case 'DateAdded':
                return 'Дата загрузки';
            default:
                return title;
        }
    }
    const HandleSongBitrate = (bitrate) => {
        return Math.round(bitrate / 1000) + ' кбит/сек';
    }
    const HandleSongDuration = (durationString) => {
        let durationParts = durationString.split(':');
        let minutes = parseInt(durationParts[0]);
        let seconds = parseInt(durationParts[1]);
        let formattedDuration = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        return formattedDuration;
    }

    const items = [];

    for (let Key in songData) {
        if (songData[Key] !== null && !['Liked', 'ID', 'File', 'Cover'].includes(Key)) {
            let value;
            if (Key === 'Bitrate') {
                value = HandleSongBitrate(songData[Key]);
            } else if (Key === 'Duration') {
                value = HandleSongDuration(songData[Key]);
            } else {
                value = songData[Key];
            }

            items.push(
                <div key={Key} className="Item">
                    <div className="InfoTitle">{HandleSongInfoTitle(Key)}:</div>
                    <div className="Value">{value}</div>
                </div>
            );
        }
    }

    return items;
}

const Music = () => {
    const params = useParams();
    const navigate = useNavigate();
    const Headers = useHeaders();
    const playerRef = useRef(null);
    const volumeRef = useRef(null);
    const [playerState, dispatchPlayer] = useReducer(playerReducer, playerInitialState);
    const [playerOpen, setPlayerOpen] = useState(false);
    const [canPlay, setCanPlay] = useState(false);

    // Категории
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([
        {
            title: 'Избранное',
            get: 'FAVORITES',
            songs: [],
            loaded: false,
            startIndex: 0
        },
        {
            title: 'Новые',
            get: 'LATEST',
            songs: [],
            loaded: false,
            startIndex: 0
        },
        {
            title: 'Случайный выбор',
            get: 'RANDOM',
            songs: [],
            loaded: false,
            startIndex: 0
        }
    ]);

    // Окно
    const [windowOpen, setWindowOpen] = useState(false);
    const [windowTitle, setWindowTitle] = useState('');
    const [windowContent, setWindowContent] = useState('');
    const [windowClass, setWindowClass] = useState('');
    const [windowContentClass, setWindowContentClass] = useState('');

    const variants = {
        hideTop: { translateY: '-150%', filter: 'blur(15px)', opacity: 0 },
        hideBottom: { translateY: '150%', filter: 'blur(15px)', opacity: 0 },
        show: {
            translateY: ['-150%', '0%'],
            filter: ['blur(15px)', 'blur(0px)'],
            opacity: [0, 1]
        },
    };

    useEffect(() => {
        for (const category of categories) {
            axios.post(`${API_DOMAIN}/LoadSongs.php?F=${category.get}`, { StartIndex: 0 }, { headers: Headers })
                .then((res) => {
                    if (res.data) {
                        setCategories(prevCategories =>
                            prevCategories.map(cat =>
                                cat.get === category.get
                                    ? { ...cat, songs: res.data, loaded: true }
                                    : cat
                            )
                        );
                    }
                });
        }
    }, []);
    useEffect(() => {
        if (params.id && !playerState.selectedSongData.ID) {
            axios.post(`${API_DOMAIN}/LoadSong.php`, { SongID: params.id }, { headers: Headers }).then((res) => {
                if (res.data) {
                    selectSong(res.data, null);
                }
            });
        }
    }, [params])

    const openAddForm = () => {
        setWindowTitle('Добавление песни');
        setWindowContent(<AddForm />);
        setWindowClass('');
        setWindowContentClass('Music-AddFrom_content');
        setWindowOpen(true);
    }

    const openSongInfo = () => {
        axios.post(`${API_DOMAIN}/LoadSong.php`, { SongID: playerState.selectedSongData.ID }, { headers: Headers }).then((res) => {
            if (res.data) {
                setWindowTitle('Информация');
                setWindowContent(<SongMetadata songData={res.data} />);
                setWindowClass('Music-FullPlayerWindow');
                setWindowContentClass('UI-ScrollView');
                setWindowOpen(true);
            }
        })
    }

    const songLike = () => {
        const songData = playerState.selectedSongData;
        if (songData.ID) {
            axios.post(`${API_DOMAIN}/MusicInteraction.php?F=LIKE`, { SongID: playerState.selectedSongData.ID }, { headers: Headers }).then((res) => {
                const updatedSongData = { ...songData, Liked: !songData.Liked };
                dispatchPlayer({ type: 'SET_SELECTED_SONG_DATA', payload: updatedSongData });
            });
        }
    }

    const loadMore = (category) => {
        axios.post(`${API_DOMAIN}/LoadSongs.php?F=${category.get}`,
            { StartIndex: category.startIndex + 25 },
            { headers: Headers })
            .then((res) => {
                if (res.data) {
                    setCategories(prevCategories =>
                        prevCategories.map(cat =>
                            cat.get === category.get
                                ? {
                                    ...cat,
                                    songs: [...cat.songs, ...res.data],
                                    loaded: true
                                }
                                : cat
                        )
                    );
                }
            });
        setCategories(prevCategories =>
            prevCategories.map(cat =>
                cat.get === category.get
                    ? { ...cat, startIndex: category.startIndex + 25 }
                    : cat
            )
        );
    }

    useEffect(() => {
        if (!playerState.selectedSong) return;

        const player = playerRef.current;

        const updateProgress = () => {
            dispatchPlayer({ type: 'SET_CURRENT_DURATION', payload: formatTime(player.currentTime) });
            dispatchPlayer({ type: 'SET_PROGRESS', payload: (player.currentTime / player.duration) * 100 });
        };

        player.addEventListener('timeupdate', updateProgress);

        return () => {
            player.removeEventListener('timeupdate', updateProgress);
        };
    }, [playerState.selectedSong]);
    useEffect(() => {
        if (!playerState.selectedSong) return;

        const player = playerRef.current;
        player.volume = playerState.volume / 100;

        const handleCanPlay = () => {
            setCanPlay(true);
        };
        const updateDuration = () => {
            dispatchPlayer({ type: 'SET_DURATION', payload: formatTime(player.duration) });
        };

        player.addEventListener('canplay', handleCanPlay);
        player.addEventListener('loadedmetadata', updateDuration);

        return () => {
            player.addEventListener('canplay', handleCanPlay);
            player.removeEventListener('loadedmetadata', updateDuration);
        };
    }, [playerState.selectedSong])

    useEffect(() => {
        if (playerState.selectedSong) {
            if (playerRef.current.paused && canPlay) {
                playerRef.current.play();
            }
        }
    }, [playerState.selectedSongData, canPlay]);

    useEffect(() => {
        if (playerState.selectedSong && canPlay) {
            if (playerState.playing && playerRef.current.paused) {
                playerRef.current.play();
            } else if (!playerState.playing && !playerRef.current.paused) {
                playerRef.current.pause();
            }
        }
    }, [playerState.playing]);

    const selectSong = (song, category) => {
        dispatchPlayer({ type: 'SET_SELECTED_SONG', payload: true });
        dispatchPlayer({ type: 'SET_SELECTED_SONG_DATA', payload: song });
        dispatchPlayer({ type: 'SET_CURRENT_COVER', payload: song.Cover });
        dispatchPlayer({ type: 'SET_OLD_COVER', payload: playerState.currentCover });
        setSelectedCategory(category);
        if (!playerState.playing) {
            dispatchPlayer({ type: 'SET_PLAYING', payload: true });
        }
        navigate(`/music/id/${song.ID}`);
    }

    const selectVolume = (e) => {
        const slider = volumeRef.current;
        const rect = slider.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const newVolume = Math.max(0, Math.min(100, (offsetX / rect.width) * 100));
        dispatchPlayer({ type: 'SET_VOLUME', payload: newVolume });
        playerRef.current.volume = newVolume / 100
        localStorage.setItem('M-Volume', newVolume / 100);
    }

    const showNav = () => {
        Animate('header', 'NAV_PANEL-SHOW', 0.3)
    }
    const hideNav = () => {
        Animate('header', 'NAV_PANEL-HIDE', 0.3)
    }

    const openPlayer = () => {
        if (playerState.selectedSong) {
            Animate('.Music-FullPlayer', 'V3-ELEMENT-SHOW', 0.4);
            Animate('.Music-FullPlayer_BG_Blur', 'V2-ELEMENT-SHOW', 0.2);
            setPlayerOpen(true);
            hideNav();
        }
    }
    const closePlayer = () => {
        Animate('.Music-FullPlayer', 'V3-ELEMENT-HIDE', 0.4);
        Animate('.Music-FullPlayer_BG_Blur', 'V2-ELEMENT-HIDE', 0.2);
        setPlayerOpen(false);
        showNav();
    }

    const togglePlay = () => {
        if (playerState.selectedSong) {
            dispatchPlayer({ type: 'SET_PLAYING', payload: !playerState.playing });
        }
    };

    const playerBack = () => {
        if (!selectedCategory || !playerState.selectedSongData) return;

        const currentIndex = selectedCategory.songs.findIndex(song => song.ID === playerState.selectedSongData.ID);
        if (currentIndex === -1) return;

        const backIndex = currentIndex === 0 ? selectedCategory.songs.length - 1 : currentIndex - 1;

        setCanPlay(false);
        selectSong(selectedCategory.songs[backIndex], selectedCategory);
    };

    const playerNext = () => {
        if (!selectedCategory || !playerState.selectedSongData) return;

        const currentIndex = selectedCategory.songs.findIndex(song => song.ID === playerState.selectedSongData.ID);
        if (currentIndex === -1) return;

        const nextIndex = (currentIndex + 1) % selectedCategory.songs.length;

        setCanPlay(false);
        selectSong(selectedCategory.songs[nextIndex], selectedCategory);
    };

    const playerChangeTime = (event) => {
        if (canPlay) {
            const percent = event.nativeEvent.offsetX / event.currentTarget.offsetWidth;
            const player = playerRef.current;
            if (player) {
                player.currentTime = percent * player.duration;
                if (!playerState.playing) {
                    dispatchPlayer({ type: 'SET_PLAYING', payload: true });
                }
            }
        }
    };

    const playerLoop = () => {
        dispatchPlayer({ type: 'SET_LOOP', payload: !playerState.loop });
    }
    const playerRandom = () => {
        dispatchPlayer({ type: 'SET_RANDOM', payload: !playerState.random });
    }
    const handleEnd = () => {
        if (playerState.loop) {
            playerRef.current.play();
        } else if (playerState.random) {
            const randomIndex = Math.floor(Math.random() * selectedCategory.songs.length);
            const randomSong = selectedCategory.songs[randomIndex];
            selectSong(randomSong, selectedCategory);
        } else {
            playerNext();
        }
    };

    return (
        <>
            <TopBar search={true} />
            <div className="Content">
                <LeftBar />
                <div className="Music-Page UI-PAGE_BODY">
                    <div className="UI-ScrollView">
                        <div className="Music-Add UI-B_FIRST">
                            <button onClick={openAddForm}>
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 0h24v24h-24z" opacity="0" transform="matrix(-1 0 0 -1 24 24)" />
                                    <path d="m19 11h-6v-6a1 1 0 0 0 -2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z" />
                                </svg>
                                Добавить песню
                            </button>
                        </div>
                        {
                            categories.map((category, i) => (
                                <Category
                                    key={i}
                                    category={category}
                                    selectSong={selectSong}
                                    loadMore={loadMore}
                                />
                            ))
                        }
                        <div className="UI-EmailInfo" style={{ marginBottom: '150px' }}>Правообладателям - abuse@netcore.group</div>
                    </div>

                    {/* Плеер */}

                    {/* Мини-плеер */}
                    <div className="Music-MiniPlayer">
                        <div className="Music-MiniPlayer_content">
                            {
                                playerState.selectedSong ? (
                                    <>
                                        <div onClick={() => { openPlayer() }} className="Music-NoneCover">
                                            <Handle_SongCover cover={playerState.selectedSongData.Cover} />
                                        </div>
                                        <div className="Metadata">
                                            <div onClick={() => { openPlayer() }} className="Name">{playerState.selectedSongData.Title}</div>
                                            <div className="Author">{playerState.selectedSongData.Artist}</div>
                                            <div className="SliderContainer">
                                                <div className="Duration">{playerState.currentDuration}</div>
                                                <div onClick={(e) => { playerChangeTime(e) }} className="Music-Slider">
                                                    {
                                                        canPlay ? (
                                                            <div style={{ 'width': `${playerState.progress}%` }} className="Progress"></div>
                                                        ) : (
                                                            <div className="UI-PRELOAD"></div>
                                                        )
                                                    }
                                                </div>
                                                <div className="Duration">{playerState.duration}</div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="Music-NoneCover">
                                            <I_Music />
                                        </div>
                                        <div className="Metadata">
                                            <div className="Name">{'Название'}</div>
                                            <div className="Author">{'Автор'}</div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="Music-ControlButtons">
                                <BackButton playerBack={playerBack} />
                                <PlayButton isPlaying={playerState.playing} togglePlay={togglePlay} />
                                <NextButton playerNext={playerNext} />
                            </div>
                        </div>
                    </div>

                    {/* Полноценный плеер */}
                    {
                        playerState.selectedSong && (
                            <>
                                <audio
                                    ref={playerRef}
                                    src={`${CDN_DOMAIN}/Content/Music/Files/${playerState.selectedSongData.File}`}
                                    onEnded={handleEnd}
                                >
                                </audio>
                                <AnimatePresence>
                                    {
                                        playerOpen && playerState.selectedSongData.Cover && (
                                            <motion.img
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                src={playerState.selectedSongData.Cover.simple_image ? `${CDN_DOMAIN}/Content/Simple/${playerState.selectedSongData.Cover.simple_image}` : `${CDN_DOMAIN}/Content/Music/Covers/${playerState.selectedSongData.Cover.image}`}
                                                className="Music-FullPlayer_BG"
                                            />
                                        )
                                    }
                                </AnimatePresence>
                                <div className="Music-FullPlayer_BG_Blur"></div>
                                <div className="Music-FullPlayer">

                                    <button onClick={() => { closePlayer() }} className="CloseButton">
                                        <I_PlayerClose />
                                    </button>

                                    <div className="Music-FullPlayer_C">
                                        <div className="Cover-Containers">
                                            <AnimatePresence mode="wait">
                                                <motion.div
                                                    key={playerState.currentCover?.image}
                                                    className="Container"
                                                    initial="hideTop"
                                                    animate="show"
                                                    exit="hideBottom"
                                                    variants={variants}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <HandleFullCover cover={playerState.currentCover} />
                                                </motion.div>
                                            </AnimatePresence>
                                            <AnimatePresence mode="wait">
                                                <motion.div
                                                    key={playerState.oldCover?.image}
                                                    className="Container"
                                                    initial="show"
                                                    animate="hideBottom"
                                                    exit="hideBottom"
                                                    variants={variants}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <HandleFullCover cover={playerState.oldCover} />
                                                </motion.div>
                                            </AnimatePresence>
                                        </div>
                                        <div className="Controls">
                                            <div className="Info">
                                                <div className="Metadata">
                                                    <div className="Name">{playerState.selectedSongData.Title}</div>
                                                    <div className="Author">{playerState.selectedSongData.Artist}</div>
                                                </div>
                                                <button onClick={openSongInfo} >
                                                    <I_SongInfo />
                                                </button>
                                                <button className={playerState.selectedSongData.Liked ? 'Active' : ''} onClick={songLike}>
                                                    <I_Like />
                                                </button>
                                            </div>
                                            <div onClick={(e) => { playerChangeTime(e) }} className="Music-Slider">
                                                {
                                                    canPlay ? (
                                                        <div style={{ 'width': `${playerState.progress}%` }} className="Progress"></div>
                                                    ) : (
                                                        <div className="UI-PRELOAD"></div>
                                                    )
                                                }
                                            </div>
                                            <div className="Music-Duration">
                                                <div>{playerState.currentDuration}</div>
                                                <div>{playerState.duration}</div>
                                            </div>
                                            <div className="Music-ControlButtons">
                                                <button onClick={playerRandom} className={`Random ${playerState.random ? 'Active' : ''}`}>
                                                    <I_Random />
                                                </button>
                                                <div className="Base">
                                                    <BackButton playerBack={playerBack} />
                                                    <PlayButton isPlaying={playerState.playing} togglePlay={togglePlay} />
                                                    <NextButton playerNext={playerNext} />
                                                </div>
                                                <button onClick={playerLoop} className={`Loop ${playerState.loop ? 'Active' : ''}`}>
                                                    <I_Loop />
                                                </button>
                                            </div>
                                            <div className="Volume">
                                                <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 10.6484V1.35163C9 0.887426 8.42247 0.673784 8.12037 1.02623L6.14966 3.3254C6.05467 3.43622 5.916 3.5 5.77003 3.5H4.5C3.67157 3.5 3 4.17157 3 5V7C3 7.82843 3.67157 8.5 4.5 8.5H5.77003C5.91599 8.5 6.05467 8.56378 6.14966 8.6746L8.12037 10.9738C8.42247 11.3262 9 11.1126 9 10.6484Z" />
                                                </svg>
                                                <div ref={volumeRef} onClick={selectVolume} className="Music-Slider">
                                                    <div style={{ 'width': `${playerState.volume}%` }} className="Progress"></div>
                                                </div>
                                                <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.5 10.6484V1.35163C6.5 0.887426 5.92247 0.673784 5.62037 1.02623L3.64966 3.3254C3.55467 3.43622 3.416 3.5 3.27003 3.5H2C1.17157 3.5 0.5 4.17157 0.5 5V7C0.5 7.82843 1.17157 8.5 2 8.5H3.27003C3.41599 8.5 3.55467 8.56378 3.64966 8.6746L5.62037 10.9738C5.92247 11.3262 6.5 11.1126 6.5 10.6484Z" />
                                                    <path d="M7.5 5C7.76522 5 8.01957 5.10536 8.20711 5.29289C8.39464 5.48043 8.5 5.73478 8.5 6C8.5 6.26522 8.39464 6.51957 8.20711 6.70711C8.01957 6.89464 7.76522 7 7.5 7V8C8.03043 8 8.53914 7.78929 8.91421 7.41421C9.28929 7.03914 9.5 6.53043 9.5 6C9.5 5.46957 9.28929 4.96086 8.91421 4.58579C8.53914 4.21071 8.03043 4 7.5 4V5Z" />
                                                    <path d="M7.5 3C8.29565 3 9.05871 3.31607 9.62132 3.87868C10.1839 4.44129 10.5 5.20435 10.5 6C10.5 6.79565 10.1839 7.55871 9.62132 8.12132C9.05871 8.68393 8.29565 9 7.5 9V10C8.56087 10 9.57828 9.57857 10.3284 8.82843C11.0786 8.07828 11.5 7.06087 11.5 6C11.5 4.93913 11.0786 3.92172 10.3284 3.17157C9.57828 2.42143 8.56087 2 7.5 2V3Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                <Window title={windowTitle} content={windowContent} className={windowClass} contentClass={windowContentClass} isOpen={windowOpen} setOpen={setWindowOpen} />
            </div>
        </>
    );
};

export default Music;