export const ApiPage_first = () => {
  return (
    <>
      <div className="BigText">Обратите внимание, что тут описано не всё API, но постепенно оно будет обновляться.</div>
      <div className="BigText">Давайте начнём с того, что установим User-Agent на «ElementAPI», так же, если некоторые скрипты возвращают пустую страницу, вам следует использовать «FormData» для отпрвки данных.</div>
      <div className="BigText">Теперь давайте войдём в аккаунт с помощью API:</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/Authorization.php?F=LOGIN</div>
        <div className="Method">POST</div>
        <div className="Data">
          Email: {`Почта`} <br></br>
          Password: {"Пароль"}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "{Ключ сессии}/{Ошибка}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Далее, если мы успешно получили ключ сессии - сохраняем его, он нам понадобиться для использования API, без него половина сткриптов не будут работать.</div>
      <div className="BigText">Теперь с этим ключом получаем данные нашего аккаунта:</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/Connect.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">GET</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              "Заголовок данных, пример «Avatar»": "Данные, пример «Avatar.png»",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Отлично, когда мы получили данные аккаунта, теперь мы можем их использовать.</div>
    </>
  );
};

export const ApiPage_Posts = () => {
  return (
    <>
      <div className="BigText">Отлично, когда мы вошли в аккаунт, теперь мы можем взаимодействовать с постами, важно отметить что посты не будут подгружаться, если вы не будете отправлять заголовок «S-KEY».</div>
      <div className="BigText">Хорошо, давайте отправим пост:</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/AddPost.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          Text: "{`Текст поста`}",
          <br />
          Files: {`[{Файл 1}, {Файл 2}, {Файл 3}]`}
          <br />
          ClearMetadataIMG: true/false
          <br />
          CensoringIMG: true/false
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "пустота (пост отправлен)/{Ошибка}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Мы умеем отправлять посты, но нужно же их получать теперь? Тут будет всё сложнее.</div>
      <div className="BigText">Давайте для начала получим один пост.</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/LoadPost.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">PostID: {`Идентификатор поста`}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              PostID: "{Идентификатор поста}",
              AuthorID: "{Идентификатор пользователя}",
              Username: "{Уникальное имя}",
              Name: "{Имя}",
              Avatar: "{Аватар/None}",
              UserIcons: "{Иконки пользователя/пусто если нет}",
              Text: "{Текст поста}",
              Content: "{Контент поста/null}",
              Date: "{Дата}",
              Likes: "{Количество лайков}",
              Dislikes: "{Количество дизлайков}",
              Liked: "{Liked/null}",
              Disliked: "{Liked/null}",
              Comments: "{Количество комментариев}",
              MyPost: "{Мой пост true/false}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Мы научились получать один пост, но этого мало.</div>
      <div className="BigText">Давайте научимся получать посты по категориям:</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/PostInteraction.php?F=
          <div className="Varianti">
            LATEST (последние), REC (рекомендации), SUBSCRIPTIONS (подписки)
          </div>
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">StartIndex: {"{Начальный индекс}"}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            [
              {
                PostID: "{Идентификатор поста}",
                AuthorID: "{Идентификатор пользователя}",
                Username: "{Уникальное имя}",
                Name: "{Имя}",
                Avatar: "{Аватар/None}",
                UserIcons: "{Иконки пользователя/пусто если нет}",
                Text: "{Текст поста}",
                Content: "{Контент поста/null}",
                Date: "{Дата}",
                Likes: "{Количество лайков}",
                Dislikes: "{Количество дизлайков}",
                Liked: "{Liked/null}",
                Disliked: "{Liked/null}",
                Comments: "{Количество комментариев}",
                MyPost: "{Мой пост true/false}",
              },
            ],
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Обычно приходит 25 постов, но тут зависит от запроса, к примеру если вы не подписаны ни на один аккаунт, и выводите подписки, вам должно вернуть пустой массив.</div>
      <div className="BigText">Хорошо, мы умеем выводить посты, но нужно научиться с ними взаимодействовать!</div>
      <div className="BigText">Давайте научимься ставить лайки и дизлайки.</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/PostInteraction.php?F=
          <div className="Varianti">
            LIKE (лайк), DISLIKE (дизлайк), DELETE (удаление)
          </div>
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">PostID: {"{Идентификатор поста}"}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "{Ответ сервера}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Хорошо, мы умеем создавать посты, выводить, и взаимодействовать с ними. Теперь давайте отправим комментарий к посту.</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/PostInteraction.php?F=POST_COMMENT
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          PostID: {`{Идентификатор поста}`}
          <br />
          Text: {`{Текст комментария}`}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "{Ответ сервера}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Так, комментарии отправлять умеем, теперь научимся их получать.</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/PostInteraction.php?F=LOAD_COMMENT
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">PostID: {`{Идентификатор поста}`}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            [
              {
                Name: "{Имя}",
                Username: "{Уникальное имя}",
                Avatar: "{Аватар/None}",
                UserIcons: "{Иконки пользователя/пусто если нет}",
                Text: "{Текст комментария}",
                Date: "{Дата}",
              },
            ],
            null,
            2
          )}
        </div>
      </div>
    </>
  );
};

export const ApiPage_Profiles = () => {
  return (
    <>
      <div className="BigText">Отлично, мы умеем получать посты, но ведь профили тоже нужно уметь получать, к слову, для этого не обязательно отправлять заголовок «S-KEY».</div>
      <div className="BigText">Хорошо, давайте получим профиль:</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/Profile.php?Username={"{Уникальное имя}"}</div>
        <div className="Headers">headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}</div>
        <div className="Method">GET</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              "Заголовок данных, пример «Avatar»": "Данные, пример «Avatar.png»"
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Мы получили данные профиля, теперь давайте получим посты, как и в случаи с обычными постами, посты пользователя не получить без ключа сессии в заголовке, а именно «S-KEY».</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/LoadPosts.php?F=USER</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          UserID: {`Идентификатор пользователя`} <br></br>
          StartIndex: {`Начальный индекс`}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            [
              {
                "PostID": "{Идентификатор поста}",
                "Text": "{Текст поста}",
                "Content": "{Контент поста/null}",
                "Date": "{Дата}",
                "Likes": "{Количество лайков}",
                "Dislikes": "{Количество дизлайков}",
                "Liked": "{Liked/null}",
                "Disliked": "{Liked/null}",
                "Comments": "{Количество комментариев}",
                "MyPost": "{Мой пост true/false}"
              }
            ],
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Мы получили посты, но, мы же хотим взаимодействовать с профилем?</div>
      <div className="BigText">Давайте подпишемся на какой-то профиль (Чтобы отписаться отправьте этот же запрос повторно), думаю очевидно что для этого в заголовке должна быть сессия, а именно «S-KEY»</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/Subscriptions.php?F=SUB_TO_USER</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          UserID: {`Идентификатор пользователя`} <br></br>
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              "Type": "Error/Verify",
              "Content": "{Ответ сервера}"
            },
            null,
            2
          )}
        </div>
      </div>
    </>
  );
};

export const ApiPage_Music = () => {
  return (
    <>
      <div className="BigText">С постами и профилями вроде всё, теперь давайте научимся работать с музыкой.</div>
      <div className="BigText">Для начала научимся выгружать музыку:</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/AddMusic.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          Title: {`Название (обязательно)`} <br></br>
          Artist: {`Исполнитель (обязательно)`} <br></br>
          Album: {`Альбом (не обязательно)`} <br></br>
          TrackNumber: {`Номер трека (не обязательно)`} <br></br>
          Genre: {`Жанр (не обязательно)`} <br></br>
          ReleaseYear: {`Дата выхода (не обязательно)`} <br></br>
          Composer: {`Композитор (не обязательно)`} <br></br>
          PostText: {`Текст поста (не обязательно)`} <br></br>
          AudioFile: {`Аудиофайл (обязательно)`} <br></br>
          CoverFile: {`Файл обложки (не обязательно)`}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "{Ответ сервера}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Мы умеем выгружать треки, давайте теперь научимся их получать по идентификатору.</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/LoadSong.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          SongID: {`Идентификатор песни`}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              "Liked": "{true/false}",
              "ID": "{Идентификатор песни}",
              "Title": "{Название}",
              "Artist": "{Исполнитель}",
              "Cover": "{Обложка}",
              "File": "{Аудиофайл}",
              "Album": "{Альбом}",
              "Genre": "{Жанр}",
              "TrackNumber": "{Номер трека}",
              "ReleaseYear": "{Дата выхода}",
              "Composer": "{Композитор}",
              "Duration": "{Длительность}",
              "Bitrate": "{Скорость потока}",
              "AudioFormat": "{Формат файла}",
              "DateAdded": "{Дата добавления}"
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Давайте теперь научимься получать музыку по категориям, это работает так же, как и с постами.</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/LoadSongs.php?F=
          <div className="Varianti">
            LATEST (последние), RANDOM (случайные), FAVORITES (избранные)
          </div>
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">StartIndex: {"{Начальный индекс}"}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            [
              {
                "ID": "{Идентификатор песни}",
                "Title": "{Название}",
                "Artist": "{Исполнитель}",
                "Cover": "{Обложка}",
                "File": "{Аудиофайл}",
                "Album": "{Альбом}",
                "Genre": "{Жанр}",
                "TrackNumber": "{Номер трека}",
                "ReleaseYear": "{Дата выхода}",
                "Composer": "{Композитор}",
                "Duration": "{Длительность}",
                "Bitrate": "{Скорость потока}",
                "AudioFormat": "{Формат файла}",
                "DateAdded": "{Дата добавления}",
                "Order": "{Песня по счёту}",
                "Liked": "{true/false}"
              },
            ],
            null,
            2
          )}
        </div>
      </div>
    </>
  );
};
