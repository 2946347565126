import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Animate } from '../System/Elements/Function';
import { API_DOMAIN, Headers } from '../System/Elements/AccountManager';
import { Handle_Avatar } from '../System/Elements/Handlers';
import { I_Logo } from '../System/UI/IconPack';
import { PreloadLastUsers } from '../System/UI/Preload';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import { InfoWindow } from '../System/Elements/Modals';

export const Authorization = () => {

  const navigate = useNavigate();
  const [windowOpen, setWindowOpen] = useState(false);
  const [windowData, setWindowData] = useState([]);

  // Капча
  const hcaptchaRef = useRef(null);
  const [token, setToken] = useState(null);

  // Данные для входа
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  // Данные для регистрации
  const [regName, setRegName] = useState('');
  const [regUsername, setRegUsername] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const [regAccept, setRegAccept] = useState(false);

  // Подтверждение почты
  const [code, setCode] = useState('');

  // Восстановление пароля (шаг 1)
  const [rEmail, setREmail] = useState('');

  // Восстановление пароля (шаг 2)
  const [rCode, setRCode] = useState('');

  // Восстановление пароля (шаг 3)
  const [rPassword, setRPassword] = useState('');

  // Переменные
  const [version, setVersion] = useState(null);
  const [lastUsers, setLastUsers] = useState([]);

  // Загрузка данных
  useEffect(() => {
    const fetchData = async () => {
      axios.get(API_DOMAIN + 'LoadElementData.php').then((response) => {
        setVersion(response.data.last_update);
        setLastUsers(response.data.users);
      })
    };
    fetchData();
  }, []);

  // Обработчики событий
  const login = (e) => {
    e.preventDefault();
    axios.post(`${API_DOMAIN}Authorization.php?F=LOGIN`, { Email: loginEmail, Password: loginPassword }, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        localStorage.setItem('S_KEY', answer.Content);
        navigate('/');
      } else if (answer.Type === 'Error') {
        setWindowData({
          title: 'Ошибка',
          text: answer.Content,
        })
        setWindowOpen(true);
      }
    })
  }
  const registration = () => {
    const data = {
      Name: regName,
      Username: regUsername,
      Email: regEmail,
      Password: regPassword,
      Accept: regAccept,
      HCaptcha: token
    };
    axios.post(`${API_DOMAIN}Authorization.php?F=REG`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        Animate('.Reg', 'AUTH-HIDE_LOGIN', 0.3);
        Animate('.Verify', 'AUTH-SHOW_REG', 0.3);
      } else if (answer.Type === 'Error') {
        setWindowData({
          title: 'Ошибка',
          text: answer.Content,
        })
        setWindowOpen(true);
      }
    })
  }
  const checkCode = () => {
    const data = {
      Code: code
    };
    axios.post(`${API_DOMAIN}Authorization.php?F=REG_V_M`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        localStorage.setItem('S_KEY', answer.Content);
        navigate('/');
      } else if (answer.Type === 'Error') {
        setWindowData({
          title: 'Ошибка',
          text: answer.Content,
        })
        setWindowOpen(true);
      }
    })
  }
  const restoreStep1 = () => {
    const data = {
      Step: 1,
      Email: rEmail,
      HCaptcha: token
    };
    axios.post(`${API_DOMAIN}Authorization.php?F=ACCOUNT_RECOVER`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        Animate('#AUTH-R_FORM_1', 'AUTH-HIDE_LOGIN', 0.3);
        Animate('#AUTH-R_FORM_2', 'AUTH-SHOW_REG', 0.3);
      } else if (answer.Type === 'Error') {
        setWindowData({
          title: 'Ошибка',
          text: answer.Content,
        })
        setWindowOpen(true);
      }
    })
  }
  const restoreStep2 = () => {
    const data = {
      Step: 2,
      Code: rCode
    };
    axios.post(`${API_DOMAIN}Authorization.php?F=ACCOUNT_RECOVER`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        localStorage.setItem('S_KEY', answer.Content);
        Animate('#AUTH-R_FORM_2', 'AUTH-HIDE_LOGIN', 0.3);
        Animate('#AUTH-R_FORM_3', 'AUTH-SHOW_REG', 0.3);
      } else if (answer.Type === 'Error') {
        setWindowData({
          title: 'Ошибка',
          text: answer.Content,
        })
        setWindowOpen(true);
      }
    })
  }
  const restoreStep3 = () => {
    const data = {
      Step: 3,
      S_KEY: localStorage.getItem('S_KEY'),
      Password: rPassword
    };
    axios.post(`${API_DOMAIN}Authorization.php?F=ACCOUNT_RECOVER`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        navigate('/');
      } else if (answer.Type === 'Error') {
        setWindowData({
          title: 'Ошибка',
          text: answer.Content,
        })
        setWindowOpen(true);
      }
    })
  }

  // Переключатель форм
  const goToReg = () => {
    Animate('.Login', 'AUTH-HIDE_LOGIN', 0.3);
    Animate('.Reg', 'AUTH-SHOW_REG', 0.3);
  }
  const goToLogin = () => {
    Animate('.Login', 'AUTH-SHOW_LOGIN', 0.3);
    Animate('.Reg', 'AUTH-HIDE_REG', 0.3);
  }
  const goToRestore = () => {
    Animate('.Login', 'AUTH-HIDE_LOGIN', 0.3);
    Animate('#AUTH-R_FORM_1', 'AUTH-SHOW_REG', 0.3);
  }
  const goToBack = (e) => {
    let thisElement = e.currentTarget;
    let target = thisElement.closest('.Auth-Container').getAttribute('target');
    let thisID = thisElement.closest('.Auth-Container').getAttribute('id');
    Animate('#' + target, 'AUTH-SHOW_LOGIN', 0.3);
    Animate('#' + thisID, 'AUTH-HIDE_REG', 0.3);
  }

  return (
    <div className="Content">
      <div className="Auth-Body UI-Block">
        <div className="Left">
          <div className="LogoAndTitle">
            <I_Logo className="Logo" />
            <div className="Title"><span>KissBoy Element</span> - соц сеть для сильных и независимых</div>
            <div className="LastUsers">
              {
                lastUsers?.length && lastUsers?.length > 0 ? (
                  lastUsers.map((user, i) => (
                    <NavLink key={i} to={`/profile/${user.Username}`} className="User">
                      <div className="Avatar">
                        <Handle_Avatar avatar={user.Avatar} name={user.Name} />
                      </div>
                      <div className="Name">
                        {user.Name}
                      </div>
                    </NavLink>
                  ))
                ) : (
                  <PreloadLastUsers />
                )
              }
            </div>
          </div>
          <div className="Wathermark">
            {
              version ? (
                `Основан на Element. contact@netcore.group`
              ) : (
                <div className="UI-PRELOAD" style={{ width: '150px', height: '12px' }}></div>
              )
            }
          </div>
        </div>
        <div className="Right">
          {/* Вход */}
          <form id="AUTH-LOGIN" className="Login" onSubmit={login}>
            <div className="Form_Container-Text">Вход</div>
            <div className="Authorization-Form">
              <input name="email" value={loginEmail} onChange={(e) => { setLoginEmail(e.target.value) }} type="email" autoComplete="email" placeholder="Почта" />
              <input name="password" value={loginPassword} onChange={(e) => { setLoginPassword(e.target.value) }} type="password" autoComplete="current-password" placeholder="Пароль" />
              <button type="submit" className="Authorization-BTN_1">Войти</button>
            </div>
            <button onClick={goToReg} className="Authorization-BTN_2" id="GO_REG">Создать аккаунт</button>
            <button onClick={goToRestore} className="Authorization-BTN_2" id="GO_RESTORE" style={{ marginTop: '5px' }}>Восстановить доступ</button>
          </form>
          {/* Регистрация */}
          <div className="Reg">
            <div className="Form_Container-Text">Создание аккаунта</div>
            <div className="Authorization-Form">
              <input value={regName} onChange={(e) => { setRegName(e.target.value) }} type="text" placeholder="Имя (Псевдоним)" />
              <input value={regUsername} onChange={(e) => [setRegUsername(e.target.value)]} type="text" placeholder="@уникальныйник" />
              <input value={regEmail} onChange={(e) => { setRegEmail(e.target.value) }} type="text" placeholder="Почта" />
              <input value={regPassword} onChange={(e) => { setRegPassword(e.target.value) }} type="text" placeholder="Пароль" />
              <HCaptcha
                sitekey="29c6b1c2-7e78-43ec-8bf8-5de49c58c54a"
                ref={hcaptchaRef}
                onVerify={setToken}
              />
              <div className="Authorization-Accept_R">
                <input onChange={() => { setRegAccept(!regAccept) }} id="RF-Accept" type="checkbox" style={{ display: 'none' }} />
                <label htmlFor="RF-Accept" className={`UI-Switch ${regAccept ? 'UI-Switch-On' : ''}`}></label>
                <div style={{ marginLeft: '10px' }}>Я принимаю <NavLink to="/info/rules" className="Authorization-Accept_R_BTN">Условия использования и Политику конфиденциальности</NavLink></div>
              </div>
              <button onClick={registration} className="Authorization-BTN_1">Создать аккаунт</button>
            </div>
            <button onClick={goToLogin} className="Authorization-BTN_2">Войти</button>
          </div>
          {/* Проверка почты */}
          <div className="Verify">
            <div className="Form_Container-Text">Проверка почты</div>
            <div className="Authorization-Form">
              <input value={code} onChange={(e) => { setCode(e.target.value) }} type="text" placeholder="Код из письма" />
              <button onClick={checkCode} className="Authorization-BTN_1">Проверить</button>
            </div>
          </div>
          {/* Восстановление доступа */}
          <div id="AUTH-R_FORM_1" target="AUTH-LOGIN" className="Auth-Container">
            <div className="Form_Container-Text">Введите почту</div>
            <div className="Authorization-Form">
              <input value={rEmail} onChange={(e) => { setREmail(e.target.value) }} type="text" placeholder="Почта" />
              <HCaptcha
                sitekey="29c6b1c2-7e78-43ec-8bf8-5de49c58c54a"
                ref={hcaptchaRef}
                onVerify={setToken}
              />
              <button onClick={restoreStep1} className="Authorization-BTN_1">Далее</button>
            </div>
            <button onClick={goToBack} className="Authorization-BTN_2">Назад</button>
          </div>
          <div id="AUTH-R_FORM_2" target="AUTH-R_FORM_1" className="Auth-Container">
            <div className="Form_Container-Text">Введите код</div>
            <div className="Authorization-Form">
              <input value={rCode} onChange={(e) => { setRCode(e.target.value) }} className="Authorization-Input" type="text" placeholder="Код" />
              <button onClick={restoreStep2} className="Authorization-BTN_1">Проверить</button>
            </div>
            <button onClick={goToBack} className="Authorization-BTN_2">Назад</button>
          </div>
          <div id="AUTH-R_FORM_3" target="AUTH-R_FORM_2" className="Auth-Container">
            <div className="Form_Container-Text">Введите новый пароль</div>
            <div className="Authorization-Form">
              <input value={rPassword} onChange={(e) => { setRPassword(e.target.value) }} className="Authorization-Input" type="text" placeholder="Пароль" />
              <button onClick={restoreStep3} className="Authorization-BTN_1">Завершить</button>
            </div>
            <button onClick={goToBack} className="Authorization-BTN_2">Назад</button>
          </div>
        </div>
      </div>
      <InfoWindow data={windowData} isOpen={windowOpen} setOpen={setWindowOpen} />
    </div>
  );
}

export default Authorization;