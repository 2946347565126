import axios from "axios";
import { useRef } from "react";
import { API_DOMAIN, GetLinks, Headers } from "../AccountManager";

export const AddLink = ({
  setOpenModals,
  setModalData,
  userDataLinks,
  setUserDataLinks,
  handlePartitionClick,
  closePartition,
}) => {
  const nameLink = useRef(null);
  const link = useRef(null);
  const add_link = ({ nameLink, link }) => {
    const fetchAddLink = async () => {
      const formData = new FormData();
      formData.append("Title", nameLink.value);
      formData.append("Link", link.value);
      const response = await axios.post(
        API_DOMAIN + `Settings.php?F=ADD_LINK`,
        formData,
        {
          headers: Headers,
        }
      );

      if (response.data.Type === "Error") {
        setOpenModals(true);
        setModalData({
          Title: "Ошибка",
          Message: response.data.Content,
        });
      }
      if (response.data.Type === "Verify") {
        setOpenModals(true);
        setModalData({
          Title: "Информация",
          Message: "Ссылка успешно добавлена",
        });
        nameLink.value = "";
        link.value = "";

        GetLinks({
          setUserDataLinks: setUserDataLinks,
          userDataLinks: userDataLinks,
          handlePartitionClick: handlePartitionClick,
        }).then(() => {
          closePartition();
        });
      }
    };
    fetchAddLink();
  };
  return (
    <>
      <img src="/System/Images/All/Settings_AddLink.png" className="UI-PB_Image" alt="фыр" />
      <input
        id="S-CP_LinkTitle"
        placeholder="Имя ссылки"
        maxLength="50"
        type="text"
        ref={nameLink}
      />
      <input
        id="S-CP_Link"
        placeholder="Ссылка"
        maxLength="150"
        type="text"
        ref={link}
      />
      <button
        className="UI-PB_NextButton"
        onClick={() => {
          const name = nameLink.current;
          const url = link.current;
          if (name.value.length > 0 && url.value.length > 0) {
            add_link({ nameLink: name, link: url });
          }
        }}
      >
        Добавить
      </button>
    </>
  );
};
