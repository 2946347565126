import { useRef, useState } from "react";
import { API_DOMAIN, AccountData, Headers } from "../AccountManager";
import axios from "axios";

export const ChangeEmail = ({ setOpenModals, setModalData }) => {
  const newEmail = useRef(null);
  const codeEmail = useRef(null);
  const [step, setStep] = useState(1);

  const fetchChangeEmail = ({ newEmail }) => {
    const formDataEmailChange = new FormData();
    formDataEmailChange.append("Step", step);
    formDataEmailChange.append("Email", newEmail.value);
    axios
      .post(API_DOMAIN + "Settings.php?F=CHANGE_EMAIL", formDataEmailChange, {
        headers: Headers,
      })
      .then((data) => {
        if (data.data.Type === "Verify") {
          setOpenModals(true);
          setModalData({
            Title: "Информация",
            Message: `Мы отправили письмо на «${newEmail.value}» с кодом`,
          });
          newEmail.value = "";
          newEmail.style.display = "none";
          codeEmail.current.style.display = "flex";
          setStep(2);
        }
        if (data.data.Type === "Error") {
          setOpenModals(true);
          setModalData({
            Title: "Ошибка",
            Message: data.data.Content,
          });
        }
      });
  };

  const fetchChangeEmailCode = ({ codeEmail, newEmail }) => {
    const formDataEmailCode = new FormData();
    formDataEmailCode.append("Step", step);
    formDataEmailCode.append("Code", codeEmail.value);
    axios
      .post(API_DOMAIN + "Settings.php?F=CHANGE_EMAIL", formDataEmailCode, {
        headers: Headers,
      })
      .then((data) => {
        if (data.data.Type === "Verify") {
          setOpenModals(true);
          setModalData({
            Title: "Информация",
            Message: "Почта успешно изменена",
          });
          setStep(1);
          codeEmail.value = "";
          codeEmail.style.display = "none";
          newEmail.style.display = "flex";
        }

        if (data.data.Type === "Error") {
          setOpenModals(true);
          setModalData({
            Title: "Ошибка",
            Message: data.data.Content,
          });
        }
      });
  };

  return (
    <>
      <img
        src="/System/Images/All/Settings_ChangeEmail.png"
        className="UI-PB_Image"
        alt="фыр"
      />
      <div id="S-CP_EmailTitle" className="UI-PB_InputText">
        Текущая: {AccountData.Email}{" "}
      </div>
      <input
        id="S-CP_Email"
        placeholder="Введите новую почту"
        type="text"
        ref={newEmail}
      />
      <input
        id="S-CP_EmailCode"
        placeholder="Код с письма"
        type="text"
        ref={codeEmail}
        style={{ display: "none" }}
      />
      <button
        id="S-CP_CE_STEP_1"
        className="UI-PB_NextButton"
        onClick={() => {
          if (step === 1) {
            fetchChangeEmail({
              newEmail: newEmail.current,
            });
          } else if (step === 2) {
            fetchChangeEmailCode({
              codeEmail: codeEmail.current,
              newEmail: newEmail.current,
            });
          }
        }}
      >
        {step === 1 ? "Отправить" : "Подтвердить"}
      </button>
    </>
  );
};
